<template>
  <div class="form-wrapper">
    <div :key="productToAdd.ticker_maitre" class="product">
      <img
        v-if="returnUrl(productToAdd.files_attachments)"
        :src="returnUrl(productToAdd.files_attachments)"
        :alt="productToAdd.title"
        class="image product-image"
      />
      <div v-else class="image not-found-wrapper">
        <img
          :src="require('@/assets/svg/v2/bottle.svg')"
          alt="Product not found image"
          class="product-not-found-image"
        />
      </div>

      <div class="product-infos">
        <p class="product-title">
          {{ productToAdd.title }}
        </p>

        <div class="product-data">
          <span>{{
            this.productToAdd.ticker
              ? this.productToAdd.ticker
              : this.productToAdd.ticker_maitre
          }}</span>
          <span class="not-display-tel">{{ productToAdd.isbn }}</span>
        </div>
      </div>

      <button v-if="!noTrash" class="product-button-remove" @click="removeProduct">
        <img
          :src="require('@/assets/svg/v2/portfolio/trashButton.svg')"
          class="themed-img"
          alt="Trash icon"
        />
      </button>
    </div>
    <div class="header-trade-type">
      <div class="header-trade-type-buy-sell">
        <span
          :class="{ 'sell-text': bottleStatus && !isSwap, 'green-text': !bottleStatus && !isSwap }"
          >{{ $t('portfolio.purchase') }}</span
        >
        <el-switch
          v-model="bottleStatus"
          :disabled="isSwap"
        />
        <span
          :class="{ 'sell-text': !bottleStatus && !isSwap, 'red-text': bottleStatus && !isSwap }"
          >{{ $t('portfolio.sale') }}</span
        >
      </div>
      <!-- <div class="header-trade-type-swap">
        <div class="price-checkbox not-display-tel">
          <el-checkbox v-model="isSwap" />
          <p>Swap</p>
        </div>
        <div>
          <span
            :class="{ 'sell-text': true, 'giver-text': !swapStatus && isSwap }"
            >Giver</span
          >
          <el-switch
            v-model="swapStatus"
            :disabled="!isSwap"
          />
          <span
            :class="{ 'sell-text': true, 'taker-text': swapStatus && isSwap }"
            >Taker</span
          >
        </div>
        <div class="price-checkbox display-tel">
          <el-checkbox v-model="isSwap" />
          <p>Swap</p>
        </div>
      </div> -->
    </div>

    <div class="date-quantity-infos">
      <div class="info">
        <label v-if="!isSwap" for="purchaseDate">{{
          bottleStatus ? $t('portfolio.sellDate') : $t('portfolio.purchaseDate')
        }}</label>
        <label v-else for="purchaseDate">{{ $t('portfolio.swap_date') }}</label>

        <div style="color: white" class="input">
          <el-date-picker
            v-model="purchaseDate"
            type="date"
            :clearable="false"
            :editable="false"
            format="DD/MM/YYYY"
            value-format="YYYY-MM-DD"
          />
        </div>
        <span class="error-message">{{ formErrors.purchaseDate }}</span>
      </div>

      <div class="info info-quantity">
        <label for="quantity">{{ $t('portfolio.th_quantity') }}</label>

        <input v-model="quantity" min="1" id="quantity" type="number" />
        <span class="error-message">{{ formErrors.quantity }}</span>
      </div>
    </div>

    <div class="product-price-infos">
      <div class="net-price">
        <label for="net-price">{{ $t('portfolio.th_net_price') }}</label>

        <div>
          <input
            v-model="netPrice"
            type="text"
            id="net-price"
            name="net-price"
          />
          <div>EUR (€)</div>
        </div>
        <span class="error-message">{{ formErrors.netPrice }}</span>
      </div>

      <div class="price-checkbox">
        <el-checkbox v-model="showPriceOptions" />
        <p>{{ $t('portfolio.addPriceOptions') }}</p>
      </div>

      <div v-if="showPriceOptions" class="price-options">
        <div>
          <label for="price-brut">{{ $t('portfolio.th_gross_price') }} </label>
          <input
            v-model="priceBrut"
            type="text"
            id="price-brut"
            name="price-brut"
            class="input"
          />
        </div>

        <div>
          <label for="price-fees">{{
            $t('portfolio.price_with_commission')
          }}</label>
          <input
            v-model="priceFees"
            type="text"
            id="price-fees"
            name="price-fees"
            class="input"
          />
        </div>
      </div>
      <div class="price-checkbox">
        <el-checkbox />
        <p>In bond</p>
      </div>
    </div>

    <div class="others-infos">
      <div class="storage-container">
        <label for="storage-area">{{
          $t('portfolio.th_storage_location')
        }}</label>
  
        <el-select
          v-model="storageLocation"
          name="storage-area"
          id="storage-area"
          :effect="darkmode ? 'dark' : ''"
        >
          <el-option
            v-for="location in getLists.port_location"
            :key="location.value"
            :label="location.label"
            :value="location.value"
          >
            {{ location.label }}
          </el-option>
        </el-select>
      </div>

      <div class="parent-folder-container">
        <label>{{ $t('portfolio.select_parent_folder') }}</label>
    
        <el-select
          v-model="parentFolder"
          name="parent-folder"
          id="parent-folder"
          :effect="darkmode ? 'dark' : ''"
        >
          <el-option
            v-for="folder in folderList"
            :key="folder.value"
            :label="folder.label"
            :value="folder.value"
          >
            {{ folder.label }}
          </el-option>
        </el-select>
      </div>

    </div>

    <button
      class="submit-button action-light"
      @click="submitForm"
      :disabled="isButtonDisabled"
    >
      {{ $t('portfolio.add_bottle') }}
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ElMessage } from 'element-plus'
import { inject } from "vue"

export default {
  name: 'secondStepForm',
  emits: ['removeProduct', 'closeModal'],
  data() {
    return {
      darkmode: inject("darkmode"),
      purchaseDate: new Date().toISOString().substr(0, 10),
      quantity: 1,
      netPrice: undefined,
      showPriceOptions: false,
      priceBrut: null,
      priceFees: null,
      inBound: false,
      storageLocation: 0,
      parentFolder: undefined,
      bottleStatus: false,
      swapStatus: false,
      isSwap: false,
      formErrors: {
        purchaseDate: '',
        quantity: '',
        netPrice: '',
      },
    }
  },
  props: {
    productToAdd: {
      type: Object,
      required: true,
    },
    noTrash: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      folders: 'pfmFolders',
      getLists: 'getLists',
      pfmSettings: 'pfmSettings',
    }),
    isButtonDisabled() {
      return !this.purchaseDate || !this.netPrice || !this.quantity
    },
    folderList() {
      const formattedData = []

      const flattenFolderTree = (folders, level) => {
        folders.forEach((folder) => {
          formattedData.push({
            label: folder.label,
            value: folder.value,
            level: level,
          })

          if (folder.children && folder.children.length > 0) {
            flattenFolderTree(folder.children, level + 1)
          }
        })
      }

      flattenFolderTree(this.folders, 0)

      return formattedData
    },
  },
  methods: {
    ...mapActions({
      createBottle: 'createBottle',
      setFolderToUpdateId: 'setFolderToUpdateId',
      getStatistics: 'getStatistics',
    }),
    returnUrl(pictures) {
      if (pictures) {
        return pictures[0]
      }
    },
    removeProduct() {
      this.$emit('removeProduct')
    },
    async submitForm() {
      if (!this.purchaseDate)
        this.formErrors.purchaseDate = 'Ce champs doit être rempli'
      if (!this.netPrice)
        this.formErrors.netPrice = 'Ce champs doit être rempli'
      if (
        this.pfmSettings.limit.bottles_count + parseInt(this.quantity) >=
        this.pfmSettings.limit.bottles_limit
      ) {
        ElMessage.error(
          'Vous avez atteint la limite de bouteilles pour votre abonnement'
        )
        this.$emit('closeModal')
      } else if (
        this.pfmSettings.limit.tickers_list.length >=
        this.pfmSettings.limit.tickers_limit
      ) {
        ElMessage.error(
          'Vous avez atteint la limite de ticker pour votre abonnement'
        )
        this.$emit('closeModal')
      } else {
        let buy_or_sell
        let swap_infos

        if (this.isSwap) {
          buy_or_sell = this.swapStatus
          swap_infos = this.swapStatus
        } else {
          buy_or_sell = this.bottleStatus
          swap_infos = null
        }
        await this.createBottle({
          folder_uuid: this.parentFolder,
          ticker: this.productToAdd.ticker
            ? this.productToAdd.ticker
            : this.productToAdd.ticker_maitre,
          sold_date: this.purchaseDate,
          price: parseInt(this.netPrice),
          currency: 'EUR',
          quantity: parseInt(this.quantity),
          buy_or_sell: buy_or_sell,
          swap_infos: swap_infos,
          options: {
            price_brut: this.priceBrut,
            price_fees: this.priceFees,
            description: null,
          },
        })

        await this.setFolderToUpdateId(this.parentFolder).then(() => {
          const payload = {
            currency: 'EUR',
            period: 'a',
          }
          this.getStatistics(payload)
        })

        this.$emit('closeModal')
      }
    },
  },
  mounted() {
    this.parentFolder = this.folderList[0].value
  },
  watch: {
    purchaseDate(newValue) {
      if (newValue.length > 0) {
        this.formErrors.purchaseDate = ''
      }
    },
    quantity(newValue) {
      if (newValue.length === 0 || newValue === 0) {
        this.formErrors.quantity =
          'Ce champs doit être rempli et sa valeur minimale à 1'
      } else {
        this.formErrors.quantity = ''
      }
    },
    netPrice(newValue) {
      if (newValue.length < 1) {
        this.formErrors.netPrice = 'Ce champs doit être rempli'
      } else {
        this.formErrors.netPrice = ''
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root';

.header-trade-type {
  display: flex;
  justify-content: space-between;

  .header-trade-type-buy-sell,
  .header-trade-type-swap,
  .price-checkbox {
    display: flex;
    align-items: center;
  }

  .header-trade-type-buy-sell,
  .header-trade-type-swap {
    align-items: center;
    .el-switch {
      margin: 0 8px;
    }
  }

  .price-checkbox {
    color:var(--text-color);
    margin: 0 20px;
    margin-bottom: 5px;

    p {
      margin-left: 10px;
      font-size: 15px;
    }
  }
}

.product-button-remove {
  width: 32px;
  height: 32px;
}

.sell-text {
  color: var(--text-grey);
}

.sell-category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: var(--text-color);
}

.green-text {
  color: $base-green-color;
}

.red-text {
  color: $alert-color;
}

.giver-text {
  color: $primary-color;
}

.taker-text {
  color: $taker-color;
}

.sell-category-text {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .product {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: 1px solid var(--border-2);
    border-radius: 12px;
    background-color: var(--background-color-3);

    .image {
      border: 1px solid var(--border-2);
      border-radius: 8px;
      height: 70px;
      width: 70px;
      max-width: 100%;
    }

    .product-infos {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: center;
      padding-left: 1rem;
      & .product-title {
        font-size: 18px;
        color: var(--text-color);
      }

      & .product-data {
        span:first-child {
          border: 1px solid var(--border-2);
          border-radius: 8px;
          color: var(--border-2);
          padding: 4px 6px;
          font-size: 14px;
        }

        span:nth-child(2) {
          font-size: 18px;
          color: var(--subtitle-text-color);
          margin-left: 10px;
        }
      }
    }

    &-button-remove {
      background-color: var(--background-color);
      color: var(--text-color);
      font-weight: bold;
      padding: 6px;
      border: none;
      border-radius: 50px;
    }
  }

  .divider {
    border-color: var(--border-2);
  }

  .date-quantity-infos {
    display: flex;
    justify-content: space-between;

    .info {
      display: flex;
      flex-direction: column;
      color: var(--text-color);
      width: 48%;

      > label {
        font-size: 1rem;
        margin-bottom: 5px;
      }

      > div {
        border: 1px solid var(--border-2);
        border-radius: 6px;
      }

      > input {
        background-color: transparent;
        border: 1px solid var(--border-2);
        border-radius: 8px;
        height: 32px;
        color: var(--text-color);
        width: 100%;
      }
    }

    .info-quantity {

      > input {
        padding: 0 10px;
        width: 93%;
      }
    }
  }

  .product-price-infos {
    color: var(--text-color);
    display: flex;
    flex-direction: column;

    .net-price {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      label {
        margin-bottom: 5px;
      }

      > div {
        width: 100%;
        max-width: 272px;
        display: flex;
        border: 1px solid var(--border-2);
        border-radius: 8px;
        overflow: hidden;

        > input {
          width: 100%;
          background-color: transparent;
          border: none;
          height: 22px;
          padding: 5px 15px;
          color: var(--text-color);
        }

        > div {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-left: 1px solid var(--border-2);
          background-color: var(--background-color-3);
        }
      }
    }

    .price-checkbox {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .price-options {
      display: flex;
      gap: 1rem;

      > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        > input {
          background-color: transparent;
          border: 1px solid var(--border-2);
          border-radius: 8px;
          height: 22px;
          padding: 5px 15px;
          color: var(--text-color);
        }
      }
    }
  }

  .others-infos {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    color: var(--text-color);
    flex-wrap: wrap;

    .storage-container, .parent-folder-container {
      display: flex;
      flex-direction: column;
      width: 48.5%;
      min-width: 200px;

      label {
        margin-bottom: 5px;
      }
    }
  }

  .submit-button {
    max-width: fit-content;
    margin: auto;
    padding: 10px 1rem;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    background-color: $primary-color;

    &:hover {
      background-color: var(--bg-primary-var-2);
    }
  }

  .error-message {
    height: 10px;
    font-size: 0.9rem;
    color: $alert-color;
  }

  .display-tel {
    display: none;
  }
}

.not-found-wrapper {
  display: flex;
  justify-content: center;
  height: 70px;
  min-width: 70px;

  img {
    width: 25px;
  }
}

@media screen and (max-width: 768px) {

  .not-display-tel {
    display: none!important;
  }

  .display-tel {
    display: flex!important;
  }

  .product-title {
    font-size: 16px!important;
  }

  .header-trade-type {
    flex-direction: column;
  }

  .sell-category {
    flex-direction: column;
    align-items: flex-start;
  }

  .info-quantity {

    > input {
      width: 87%!important;
    }
  }

  .product-price-infos {
    .net-price {
      align-items: flex-start;
      width: 100%;

      > div {
        max-width: none!important;

        > input {
          width: 100%;
        }
      }
    }

    .price-options {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  .others-infos {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .storage-container, .parent-folder-container {
      width: 100%!important;
    }
  }

  .submit-button {
    width: 100%;
  }
}
</style>
